import { Heading, Container, Page } from '@troon/ui';
import { For } from 'solid-js';
import { Meta, Title } from '@solidjs/meta';
import { NoHydration } from 'solid-js/web';
import { createAsync } from '@solidjs/router';
import { getConfigValue } from '../../modules/config';
import { Hero } from '../../components/hero/photo';
import { ContentCard } from '../../components/card';
import { createContentfulRequest } from '../content/[model]/_client';
import type { RouteDefinition } from '@solidjs/router';

export default function PlayTroonProgramsPage() {
	const cards = createAsync(async () => getCards('play-troon-programs'), { deferStream: true });
	return (
		<NoHydration>
			<Title>Play Troon Programs | Troon</Title>
			<Meta
				name="description"
				content="Troon is dedicated to growing the game of golf and has implemented initiatives and programs to help you and your family play more. Check out all of the #PlayTroon opportunities below."
			/>
			<Hero src={`${getConfigValue('IMAGE_HOST')}/web/hero/play-troon-programs.jpeg`}>
				<Heading as="h1" class="text-center ">
					Play Troon Programs
				</Heading>
				<p>
					Troon is dedicated to growing the game of golf and has implemented initiatives and programs to help you and
					your family play more. Check out all of the #PlayTroon opportunities below.
				</p>
			</Hero>

			<Container>
				<Page>
					<div class="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-8 xl:grid-cols-3">
						<For each={cards()?.fields.cards}>{(program) => <ContentCard {...program!.fields} />}</For>
					</div>
				</Page>
			</Container>
		</NoHydration>
	);
}

const getCards = createContentfulRequest('cards');

export const route = {
	preload: () => getCards('play-troon-programs'),
	info: { nav: { hero: true } },
} satisfies RouteDefinition;
